import { useContext } from "react";
import AppContext from "../context/AppContext";

export const STEPS = {
  Vehicle: "Vehicle Information",
  Appointment: "Appointment",
  Customer: "Contact Information",
};

const Stepper = ({ step }) => {
  const {
    brand: {
      backgroundColor,
      textColor,
      stepperBackgroundColor,
      stepperTextColor,
    },
  } = useContext(AppContext);

  return (
    <>
      <ol
        className="flex flex-row p-2 bg-slate-200 rounded mb-4"
        style={{
          backgroundColor: stepperBackgroundColor || "",
          color: stepperTextColor || "",
        }}
      >
        {Object.keys(STEPS).map((key) => {
          return (
            <li
              key={key}
              className={`grow text-l text-center p-2 ${
                STEPS[key] === step ? "bg-white rounded" : ""
              }`}
              style={
                STEPS[key] === step
                  ? {
                      backgroundColor: backgroundColor || "",
                      color: textColor || "",
                    }
                  : {}
              }
            >
              <span>{STEPS[key]}</span>
            </li>
          );
        })}
      </ol>
    </>
  );
};

export default Stepper;
