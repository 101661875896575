import { BASE_URL } from "../constants";

const ContextService = {
  getContext: () =>
    fetch(`${BASE_URL}/context`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),
};

export default ContextService;
