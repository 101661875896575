import { Button, Datepicker, Select, TextInput } from "flowbite-react";
import { Controller, useForm } from "react-hook-form";

const padLeft = (value, len) => {
  var str = `${value}`;
  return `${
    str.length >= len ? "" : new Array(len - str.length).fill(0).join()
  }${str}`;
};

const EditVehicleForm = ({ onConfirm, onCancel }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onConfirm)}>
      <div className="mt-8">
        <p className="text-xl">Please enter your vehicle information</p>

        <div className="grid grid-cols-2 gap-x-16 gap-y-4 mt-4">
          <div>
            <label>Make</label>
            <TextInput
              {...register("make", { required: true })}
              className="w-full"
              color={errors?.make ? "failure" : ""}
            />
            {errors?.make && (
              <p className="text-sm text-red-500">Make is required</p>
            )}
          </div>
          <div>
            <label>Model</label>
            <TextInput
              {...register("model", { required: true })}
              className="w-full"
              color={errors?.model ? "failure" : ""}
            />
            {errors?.model && (
              <p className="text-sm text-red-500">Model is required</p>
            )}
          </div>
          <div>
            <label>Year of Manufacture</label>
            <TextInput
              {...register("yearOfManufacture", {
                required: true,
                pattern: /^\d{4}$/,
              })}
              className="w-full"
              type="number"
              color={errors?.yearOfManufacture ? "failure" : ""}
            />
            {errors?.yearOfManufacture && (
              <p className="text-sm text-red-500">
                Year of Manufacture is required
              </p>
            )}
          </div>
          <div>
            <label>First Registration Date</label>
            <Controller
              name="regDate"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  language="en-GB"
                  placeholder="DD-MM-YYYY"
                  weekStart={1}
                  value={value ? value.split("T")[0] : value}
                  onSelectedDateChanged={(date) =>
                    onChange(
                      `${date.getFullYear()}-${padLeft(
                        date.getMonth(),
                        2
                      )}-${padLeft(date.getDate(), 2)}T00:00:00`
                    )
                  }
                />
              )}
            ></Controller>
            {errors?.regDate && (
              <p className="text-sm text-red-500">
                First Registration Date is required
              </p>
            )}
          </div>
          <div>
            <label>Transmission</label>
            <Select
              {...register("transmission", { required: true })}
              color={errors?.transmission ? "failure" : ""}
            >
              <option></option>
              <option>MANUAL</option>
              <option>AUTOMATIC</option>
            </Select>
            {errors?.transmission && (
              <p className="text-sm text-red-500">Transmission is required</p>
            )}
          </div>
          <div>
            <label>Fuel</label>
            <Select
              {...register("fuel", { required: true })}
              color={errors?.fuel ? "failure" : ""}
            >
              <option></option>
              <option>PETROL</option>
              <option>DIESEL</option>
              <option>HYBRID</option>
            </Select>
            {errors?.fuel && (
              <p className="text-sm text-red-500">Fuel is required</p>
            )}
          </div>
          <div>
            <label>Engine Capacity</label>
            <TextInput
              {...register("engineCapacity", {
                required: true,
                pattern: /^\d+$/,
              })}
              className="w-full"
              type="number"
              color={errors?.engineCapacity ? "failure" : ""}
            />
            {errors?.engineCapacity && (
              <p className="text-sm text-red-500">
                Engine Capacity is required
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="mt-8">
        <Button type="submit" color="dark" className="mx-auto w-48">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default EditVehicleForm;
