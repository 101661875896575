import { useContext, useEffect } from "react";
import AppContext from "../context/AppContext";

const Header = () => {
  const {
    brand: {
      headerBackgroundColor,
      headerTextColor,
      headerFont,
      logoFile,
      title,
    },
  } = useContext(AppContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div
      className="bg-black px-4 py-4"
      style={{
        backgroundColor: headerBackgroundColor || "",
        color: headerTextColor || "",
        fontFamily: headerFont || "",
      }}
    >
      {logoFile ? (
        <img src={"/" + logoFile} alt={title} title={title} className="h-8" />
      ) : (
        <img
          src="/logo.png"
          alt="Feasa | Part of CitNOW Group"
          className="h-8"
        />
      )}
    </div>
  );
};

export default Header;
