import { useState, useEffect, useContext } from "react";

import { Button, Badge, Card } from "flowbite-react";

import { Actions } from "../reducers/Booking";
import Stepper, { STEPS } from "../components/Stepper";

import rtc from "../services/rtc";

import { FaCar } from "react-icons/fa";
import AppContext from "../context/AppContext";

const Page = ({ state, dispatch }) => {
  const [dealers, setDealers] = useState(null);
  const [limit, setLimit] = useState(4);

  const { groups } = useContext(AppContext);

  useEffect(() => {
    setLimit(4);

    rtc
      .getDealers()
      .then((json) => {
        setDealers(
          json.filter(
            (d) =>
              !(
                groups &&
                groups[d.dealerId] &&
                groups[d.dealerId].visible === false
              )
          )
        );
      })
      .catch((err) => console.error(err));
  }, [groups, state]);

  const selectDealer = (dealer) => {
    dispatch({
      type: Actions.SetDealer,
      payload: dealer,
    });
  };

  return (
    <>
      <Stepper step={STEPS.Appointment} />

      <h2 className="text-xl block mb-8">
        Please select your preferred service location
      </h2>

      {Array.isArray(dealers) && (
        <>
          {dealers
            .filter((_, idx) => idx < limit)
            .map((d) => (
              <Card
                className="mb-8 md:w-3/4 md:m-auto md:mb-8"
                key={d.dealerId}
              >
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <span
                    title={`${groups[d.dealerId]?.name || d.description} (${
                      d.dealerId
                    })`}
                  >
                    {groups[d.dealerId]?.name || d.description}
                  </span>
                </h5>

                <p>
                  {(d.marque || "").split(/[|,]/).map((m) => (
                    <Badge
                      key={m}
                      size="xs"
                      color="gray"
                      className="inline-block mr-1"
                    >
                      {m}
                    </Badge>
                  ))}
                </p>

                <Button
                  type="button"
                  color="dark"
                  className="w-full"
                  onClick={() => selectDealer(d)}
                >
                  Select
                </Button>
              </Card>
            ))}

          {limit < dealers.length && (
            <Button
              type="button"
              color="gray"
              className="m-auto"
              onClick={() => setLimit((l) => l + 4)}
            >
              Show more dealerships
            </Button>
          )}
        </>
      )}
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
