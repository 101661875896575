import { BASE_URL } from "../constants";

const RTCService = {
  getCustomerDetails: (customerId, vehicleId) =>
    fetch(`${BASE_URL}/details/${customerId}/${vehicleId}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) =>
        res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
      )
      .then((res) =>
        res.customer && res.vehicle && res.vehicle.registration
          ? RTCService.getVehicle(null, res.vehicle.registration)
              .then((vehicle) => ({ ...res, vehicle }))
              .catch(() =>
                Promise.resolve({
                  ...res,
                  regNum: res.vehicle.registration,
                  vehicle: {
                    ...res.vehicle,
                    regNo: res.vehicle.registration,
                  },
                })
              )
          : Promise.reject({
              error: "Could not find customer details and/or vehicle",
            })
      ),

  getDealers: () =>
    fetch(`${BASE_URL}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getVehicle: (dealerId, regNo) =>
    fetch(`${BASE_URL}/vehicles/${regNo}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getOperations: (dealerId, regNo, model, regDate) =>
    fetch(
      `${BASE_URL}/${dealerId}/vehicles/${
        regNo || ""
      }/operations?model=${model}&reg=${regDate}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getAppointments: (dealerId, regNo, model, regDate, from, to, ops) =>
    fetch(
      `${BASE_URL}/${dealerId}/vehicles/${regNo}/availability?model=${model}&reg=${regDate}&from=${from}&to=${to}&${ops
        .map((r) => `code=${r.code}`)
        .join("&")}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  getPaymentMethods: (dealerId) =>
    fetch(`${BASE_URL}/${dealerId}/payments`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),

  createBooking: (dealerId, booking) =>
    fetch(`${BASE_URL}/${dealerId}/bookings`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(booking),
    }).then((res) =>
      res.ok ? res.json() : res.json().then((r) => Promise.reject(r))
    ),
};

export default RTCService;
