import { Button, Spinner, TextInput } from "flowbite-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import rtc from "../services/rtc";

const RegnumSearchForm = ({ onSearch }) => {
  const [loading, setLoading] = useState(false);

  const handleSearch = ({ regnum }) => {
    setLoading(true);
    rtc
      .getVehicle(null, regnum)
      .then((vehicle) => onSearch({ regnum, ...vehicle }))
      .catch((error) => onSearch({ error }))
      .finally(() => setLoading(false));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <form onSubmit={handleSubmit(handleSearch)}>
        <h2 className="text-xl block mb-8">
          Please enter your vehicle registration
        </h2>
        <div className="flex gap-4 justify-center">
          <div className="w-80">
            <TextInput
              {...register("regnum", { required: true })}
              className="w-full"
              color={errors?.regnum ? "failure" : ""}
            />
            {errors?.regnum && (
              <p className="text-sm text-red-500">
                Vehicle Registration Number is required
              </p>
            )}
          </div>
          <div className="w-48">
            <Button type="submit" color="dark" className="w-full">
              Find Vehicle
            </Button>
          </div>
        </div>

        {loading && (
          <div className="text-center mt-8">
            <Spinner className="h-10 w-10 fill-black" />
          </div>
        )}
      </form>
    </>
  );
};

export default RegnumSearchForm;
