import { Button } from "flowbite-react";

const ConfirmVehicleDetails = ({ vehicle, onConfirm, onCancel }) => {
  return (
    <>
      {vehicle && (
        <div className="mt-8">
          <p className="text-xl font-bold">Is this your vehicle?</p>
          <div className="my-8">
            <p>
              {vehicle.make} {vehicle.model}
            </p>
            <p>{vehicle.description}</p>
          </div>
          <div className="flex gap-16 justify-center">
            <div className="w-48">
              <Button onClick={onConfirm} color="dark" className="w-full">
                Yes, this is correct
              </Button>
            </div>
            <div className="w-60">
              <Button
                type="button"
                color="gray"
                className="w-full"
                onClick={onCancel}
              >
                No, manually enter vehicle
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmVehicleDetails;
