import { useReducer, useMemo } from "react";

import Header from "./components/Header";

import { reducer, initialState, States } from "./reducers/Booking";

import Initial from "./steps/Initial";
import Vehicle from "./steps/Vehicle";
import Dealer from "./steps/Dealer";
import Operations from "./steps/Operations";
import Appointment from "./steps/Appointment";
import Customer from "./steps/Customer";
import Review from "./steps/Review";
import Complete from "./steps/Complete";
import { ToastContainer, Zoom } from "react-toastify";
import { AppContextProvider } from "./context/AppContext";

const SPLIT_SCREEN = [
  States.INITIAL,
  States.VEHICLE,
  States.DEALER,
  States.OPERATIONS,
  States.APPOINTMENT,
  States.CUSTOMER,
];

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const props = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <>
      <AppContextProvider>
        <>
          <Header />
          <div className="md:container md:m-auto">
            <div className="flex flex-col-reverse md:flex-row px-4 py-16 w-full max-w-full">
              {SPLIT_SCREEN.includes(state.state) && (
                <div className="flex-none basis-full md:basis-8/12">
                  {state.state === States.INITIAL && (
                    <Initial.Page {...props} />
                  )}
                  {state.state === States.VEHICLE && (
                    <Vehicle.Page {...props} />
                  )}
                  {state.state === States.DEALER && <Dealer.Page {...props} />}
                  {state.state === States.OPERATIONS && (
                    <Operations.Page {...props} />
                  )}
                  {state.state === States.APPOINTMENT && (
                    <Appointment.Page {...props} />
                  )}
                  {state.state === States.CUSTOMER && (
                    <Customer.Page {...props} />
                  )}
                </div>
              )}
              {SPLIT_SCREEN.includes(state.state) && (
                <div className="flex-none basis-full md:basis-4/12 md:pl-8 mb-8">
                  {state.state === States.INITIAL && (
                    <Initial.Description state={state} />
                  )}
                  {state.state === States.VEHICLE && (
                    <Vehicle.Description state={state} />
                  )}
                  {state.state === States.DEALER && (
                    <Dealer.Description state={state} />
                  )}
                  {state.state === States.OPERATIONS && (
                    <Operations.Description state={state} />
                  )}
                  {state.state === States.APPOINTMENT && (
                    <Appointment.Description state={state} />
                  )}
                  {state.state === States.CUSTOMER && (
                    <Customer.Description state={state} />
                  )}
                </div>
              )}

              {!SPLIT_SCREEN.includes(state.state) && (
                <div className="flex-none basis-full md:m-auto md:basis-8/12">
                  {state.state === States.REVIEW && <Review.Page {...props} />}
                  {state.state === States.COMPLETE && (
                    <Complete.Page {...props} />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      </AppContextProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
        transition={Zoom}
      />
    </>
  );
};

export default App;
