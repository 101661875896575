import { useContext, useState } from "react";

import { Button, TextInput } from "flowbite-react";

import { Actions } from "../reducers/Booking";
import Stepper, { STEPS } from "../components/Stepper";
import { FaCar, FaMapMarkerAlt, FaTools, FaCalendarAlt } from "react-icons/fa";

import { MONTHS, APPOINTMENT_TYPE } from "../constants";
import AppContext from "../context/AppContext";

const Page = ({ state, dispatch }) => {
  const [customer, setCustomer] = useState({
    title: "",
    forename: "",
    surname: "",
    postcode: "",

    address1: "",
    address2: "",

    address3: "", // Town/City
    address4: "", // Country

    phone1: "", // Land line
    phone2: "", // Mobile

    email: "",

    preferredPhone: 1,

    salutation: "",
    address5: "",
    address6: "",
    phone3: "",
    phone4: "",
    courtesyVehicleDriverDOB: "1970-01-01T00:00:00",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    var payload = customer;
    dispatch({
      type: Actions.SetCustomer,
      payload,
    });
  };

  return (
    <>
      <Stepper step={STEPS.Customer} />

      <h2 className="text-xl block mb-8">
        Please enter your contact information
      </h2>

      <div className="grid grid-cols-2 gap-x-16 gap-y-4 mt-4">
        <div>
          <label>Title</label>
          <TextInput
            className="w-full"
            value={customer.title}
            onChange={(event) =>
              setCustomer((cust) => ({ ...cust, title: event.target.value }))
            }
          />
        </div>
        <div className="col-start-1">
          <label>First Name</label>
          <TextInput
            className="w-full"
            value={customer.forename}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                forename: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Last Name</label>
          <TextInput
            className="w-full"
            value={customer.surname}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                surname: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Postcode</label>
          <TextInput
            className="w-full"
            value={customer.postcode}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                postcode: event.target.value,
              }))
            }
          />
        </div>
        <div className="col-start-1">
          <label>Address 1</label>
          <TextInput
            className="w-full"
            value={customer.address1}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                address1: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Address 2</label>
          <TextInput
            className="w-full"
            value={customer.address2}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                address2: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Town / City</label>
          <TextInput
            className="w-full"
            value={customer.address3}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                address3: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Country</label>
          <TextInput
            className="w-full"
            value={customer.address4}
            onChange={(event) =>
              setCustomer((cust) => ({
                ...cust,
                address4: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Landline</label>
          <TextInput
            className="w-full"
            value={customer.phone1}
            onChange={(event) =>
              setCustomer((cust) => ({ ...cust, phone1: event.target.value }))
            }
          />
        </div>
        <div>
          <label>Mobile</label>
          <TextInput
            className="w-full"
            value={customer.phone2}
            onChange={(event) =>
              setCustomer((cust) => ({ ...cust, phone2: event.target.value }))
            }
          />
        </div>
        <div className="col-span-2">
          <label>Email address</label>
          <TextInput
            className="w-full"
            value={customer.email}
            onChange={(event) =>
              setCustomer((cust) => ({ ...cust, email: event.target.value }))
            }
          />
        </div>
      </div>

      <div className="mt-8">
        <Button
          type="button"
          color="dark"
          className="w-full md:w-3/4 md:m-auto"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaMapMarkerAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Selected Location
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.dealer.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaTools size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Service
      </h3>
      {state.repairOperations.map((ro, idx) => (
        <p
          className="text-xl text-slate-500"
          style={{ color: textShadeColor }}
          key={ro.code}
        >
          {ro.description}
        </p>
      ))}

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCalendarAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Appointment
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {APPOINTMENT_TYPE[state.availability.value]}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {MONTHS[state.availability.date.getMonth()]}{" "}
        {state.availability.date.getDate()},{" "}
        {state.availability.date.getFullYear()} at {state.availability.time}
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
