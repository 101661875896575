import { useState, useMemo, useEffect, useContext } from "react";

import { Button, Datepicker, Select } from "flowbite-react";

import { Actions } from "../reducers/Booking";

import Stepper, { STEPS } from "../components/Stepper";
import AppointmentOption from "../components/AppointmentOption";

import rtc from "../services/rtc";
import { FaCar, FaMapMarkerAlt, FaTools } from "react-icons/fa";
import AppContext from "../context/AppContext";

const timeToHuman = (time) => {
  const parts = ("" + time).split(".");

  const hour = parts[0];
  const minute = parseInt(parts[1] || "0", 10) * 10 + "";

  return (
    (hour.length === 1 ? "0" : "") +
    hour +
    ":" +
    (minute.length === 1 ? "0" : "") +
    minute
  );
};

const dateToHuman = (date) => {
  const parts = ("" + date).split("T");
  return parts[0];
};

const formatPrice = (price) => {
  return (
    "£" + (price || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })
  );
};

const Page = ({ state, dispatch }) => {
  const [availabilities, setAvailabilities] = useState(null);

  useEffect(() => {
    const today = new Date();

    const from =
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        .toISOString()
        .split("T")[0] + "T00:00:00";

    const to =
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 31)
        .toISOString()
        .split("T")[0] + "T00:00:00";

    rtc
      .getAppointments(
        state.dealer.dealerId,
        state.vehicle.regNo,
        state.vehicle.model || "",
        // (state.vehicle.model || "").split(" ")[0],
        state.vehicle.regDate || "",
        from,
        to,
        state.repairOperations
      )
      .then((json) => setAvailabilities(json))
      .catch((err) => console.error(err));
  }, [state]);

  const availabilitiesWait = useMemo(() => {
    if (!Array.isArray(availabilities)) {
      return undefined;
    }

    return availabilities
      .map((day) => ({
        ...day,
        availableTimes: day.availableTimes.filter(
          (time) => !!time.whileYouWait
        ),
      }))
      .filter((day) => day.availableTimes.length > 0);
  }, [availabilities]);

  const availabilitiesCourtesyCar = useMemo(() => {
    if (!Array.isArray(availabilities)) {
      return undefined;
    }

    return availabilities.filter((day) => !!day.courtesyVehicle);
  }, [availabilities]);

  const availabilitiesCourtesyLift = useMemo(() => {
    if (!Array.isArray(availabilities)) {
      return undefined;
    }

    return availabilities.filter((day) => !!day.courtesyLift);
  }, [availabilities]);

  const availabilitiesDropoff = useMemo(() => {
    if (!Array.isArray(availabilities)) {
      return undefined;
    }

    return availabilities;
  }, [availabilities]);

  const [selected, setSelected] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selected || !selected.date || !selected.time) {
      return;
    }

    dispatch({
      type: Actions.SetAppointment,
      payload: {
        ...selected,
      },
    });
  };

  const [min, max, times] = useMemo(() => {
    if (!selected || !Array.isArray(selected.options)) {
      return [new Date(), new Date(), []];
    }

    const t = selected.options
      .filter((day) => dateToHuman(day.availableDate))
      .map((day) => day.availableTimes)[0]
      .filter((time) => {
        if (dateToHuman(new Date().toISOString()) === selected.date) {
          const [hourPart, minutePart] = new Date()
            .toISOString()
            .split("T")[1]
            .split(":");
          return timeToHuman(time) > `${hourPart}:${minutePart}`;
        } else {
          return true;
        }
      });

    return [
      new Date(selected.options[0].availableDate),
      new Date(selected.options[selected.options.length - 1].availableDate),
      t,
    ];
  }, [selected]);

  return (
    <>
      <Stepper step={STEPS.Appointment} />

      <h2 className="text-xl block mb-8">Please select your appointment</h2>

      <div>
        <AppointmentOption
          title="Wait at dealership"
          value="wait"
          selected={selected?.value === "wait"}
          options={availabilitiesWait}
          onChange={setSelected}
        />
        <AppointmentOption
          title="Dropoff"
          value="dropoff"
          selected={selected?.value === "dropoff"}
          options={availabilitiesDropoff}
          onChange={setSelected}
        />
        <AppointmentOption
          title="Courtesy lift"
          value="lift"
          selected={selected?.value === "lift"}
          options={availabilitiesCourtesyLift}
          price={formatPrice(25)}
          onChange={setSelected}
        />
        <AppointmentOption
          title="Courtesy car"
          value="car"
          selected={selected?.value === "car"}
          options={availabilitiesCourtesyCar}
          price={formatPrice(25)}
          onChange={setSelected}
        />

        {selected && selected.options && (
          <>
            <h2 className="text-xl block mb-4">
              Please confirm your appointment date ant timeslot
            </h2>

            <div className="flex justify-center gap-4">
              <div className="w-64">
                <Datepicker
                  minDate={min}
                  maxDate={max}
                  key={selected.value}
                  defaultValue={selected.date}
                  weekStart={1}
                  language="en-GB"
                  className="w-full"
                  onSelectedDateChanged={(date) => {
                    setSelected((prev) => ({ ...prev, date, time: "" }));
                  }}
                  theme={{
                    popup: {
                      root: { base: "absolute z-50 block pt-2 -top-96" },
                    },
                  }}
                />
              </div>

              <div className="w-48">
                <Select
                  className="w-full"
                  value={selected.time}
                  onChange={(evt) =>
                    setSelected((prev) => ({
                      ...prev,
                      time: evt.target.value,
                    }))
                  }
                  key={selected.date.toISOString()}
                >
                  <option></option>
                  {Array.isArray(times) &&
                    times.map((time) => (
                      <option
                        key={timeToHuman(time.time)}
                        value={timeToHuman(time.time)}
                      >
                        {timeToHuman(time.time)}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="mt-8">
        <Button
          type="button"
          color="dark"
          className="w-full md:w-3/4 md:m-auto"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaMapMarkerAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Selected Location
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.dealer.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaTools size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Service
      </h3>
      {state.repairOperations.map((ro, idx) => (
        <p
          className="text-xl text-slate-500"
          style={{ color: textShadeColor }}
          key={ro.code}
        >
          {ro.description}
        </p>
      ))}
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
