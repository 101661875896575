export const BASE_URL = ((hostname) => {
  let sub = "stage";
  if (hostname.indexOf("booking.getauto.io") > 0) {
    if (hostname.indexOf(".") === hostname.indexOf(".booking.getauto.io")) {
      sub = "";
    } else {
      sub = hostname.substring(
        hostname.indexOf(".") + 1,
        hostname.indexOf(".booking.getauto.io")
      );
    }
  }

  return `https://${sub}${sub.length > 0 ? "." : ""}api.getauto.io/rtc/osb`;
})(window.location.hostname);

export const MONTHS =
  "January,February,March,April,May,June,July,August,September,October,November,December".split(
    ","
  );

export const APPOINTMENT_TYPE = {
  wait: "Wait at dealership",
  dropoff: "Dropoff",
  lift: "Courtesy lift",
  car: "Courtesy car",
};
