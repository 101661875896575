import { useContext, useEffect, useState } from "react";

import { Alert, Button, Spinner } from "flowbite-react";
import { FaRegUserCircle } from "react-icons/fa";

import InitialDetails from "../components/InitialDetails";

import rtc from "../services/rtc";
import { Actions } from "../reducers/Booking";
import AppContext from "../context/AppContext";

// Customers
// 0038d00000WaBJPAA3, 0038d00000McHTJAA3, 0038d00000Jqmj2AAB

// Vehicles
// 0vL8d000000ChKvEAK, 0vL8d000000ChPMEA0

const Page = ({ state, dispatch }) => {
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(false);

  const handleNewBooking = () => {
    dispatch({
      type: Actions.Start,
      payload: {},
    });
  };

  const handleExistentBooking = () => {
    dispatch({
      type: Actions.Start,
      payload: {
        existentCustomer: true,
        customer: {
          ...details.customer,
          preferredPhone: 1,
          salutation: "",
          address5: "",
          address6: "",
          phone3: "",
          phone4: "",
          courtesyVehicleDriverDOB: "1970-01-01T00:00:00",
        },
        vehicle: { ...details.vehicle, regnum: details.vehicle.regNo },
      },
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    var customerId = searchParams.get("customer");
    var vehicleId = searchParams.get("vehicle");

    if (!customerId || !vehicleId) {
      dispatch({
        type: Actions.Start,
        payload: {},
      });
    } else {
      rtc
        .getCustomerDetails(customerId, vehicleId)
        .then((res) => setDetails(res))
        .catch((err) => setError(err));
    }
  }, [dispatch]);

  return (
    <>
      {!error && !details && (
        <div className="text-center">
          <Spinner className="h-20 w-20 fill-black" />
        </div>
      )}
      <InitialDetails
        details={details}
        onConfirm={handleExistentBooking}
        onCancel={handleNewBooking}
      />
      {error && (
        <Alert
          color="failure"
          additionalContent={
            <Button color="dark" onClick={handleNewBooking}>
              Start Booking
            </Button>
          }
        >
          Could not find details about you or your vehicle. If you would like to
          start a booking as a new customer, please click
        </Alert>
      )}
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Book your service
      </h2>
      <p
        className="pt-4 text-3xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        At Feasa Auto, having your vehicle serviced by one of our manufacturer
        trained technicians gives you the peace of mind that your vehicle won't
        let you down when you need it most.
      </p>

      <div className="py-4" style={{ color: textColor }}>
        <FaRegUserCircle size="2em" />
      </div>

      <h3 className="text-2xl font-bold" style={{ color: textColor }}>
        Maintain your vehicle warranty
      </h3>
      <p
        className="pt-4 text-xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        Manufacturer warranties require your vehicle to be serviced within set
        guidelines, and missing a service may mean you have to pay for expensive
        repairs which would usually be covered for you
      </p>

      <div className="py-4" style={{ color: textColor }}>
        <FaRegUserCircle size="2em" />
      </div>

      <h3 className="text-2xl font-bold" style={{ color: textColor }}>
        Safety first
      </h3>
      <p
        className="pt-4 text-xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        We carry out a visual health check of all areas of your vehicke, even
        those not covered in a standard service, so we can let you know of any
        potential safety issues and help you get them fixed before they turn
        into bigger repairs
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
