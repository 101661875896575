import { useState } from "react";

import { Alert } from "flowbite-react";

import { Actions } from "../reducers/Booking";
import Stepper, { STEPS } from "../components/Stepper";

import Initial from "./Initial";
import RegnumSearchForm from "../components/RegnumSearchForm";
import ConfirmVehicleDetails from "../components/ConfirmVehicleDetails";
import EditVehicleForm from "../components/EditVehicleForm";

// KY69EZL (Seat Leon) YF68KGX (Skoda Karoq) Y802KAN (Audi A4)

const Page = ({ state, dispatch }) => {
  const [result, setResult] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleConfirm = (data) => {
    dispatch({
      type: Actions.SetVehicle,
      payload: { ...result },
    });
  };

  return (
    <>
      <Stepper step={STEPS.Vehicle} />

      {!editMode && <RegnumSearchForm onSearch={setResult} />}

      {!editMode && (
        <ConfirmVehicleDetails
          vehicle={result}
          onConfirm={handleConfirm}
          onCancel={() => setEditMode(true)}
        />
      )}

      {editMode && <EditVehicleForm onConfirm={handleConfirm} />}
      {result?.error && (
        <div className="mt-8">
          <Alert color="failure">{result.error.error}</Alert>
        </div>
      )}
    </>
  );
};

const tuple = { Page, Description: Initial.Description };

export default tuple;
