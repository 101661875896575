import { useState } from "react";

import { Button, Radio, Alert } from "flowbite-react";

import { Actions } from "../reducers/Booking";

import rtc from "../services/rtc";
import {
  FaCalendarAlt,
  FaCar,
  FaMapMarkerAlt,
  FaRegUserCircle,
  FaTools,
} from "react-icons/fa";

import { MONTHS, APPOINTMENT_TYPE } from "../constants";

const Page = ({ state, dispatch }) => {
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    setError(null);

    const booking = {
      appointmentTime: `${state.availability.date.getFullYear()}-${
        state.availability.date.getMonth() > 8 ? "" : "0"
      }${state.availability.date.getMonth() + 1}-${
        state.availability.date.getDate() > 9 ? "" : "0"
      }${state.availability.date.getDate()}T${state.availability.time}:00Z`,
      courtesyLift: false,
      courtesyVehicle: false,
      courtesyVehicleCharge: 0.0,
      courtesyVehicleSelfInsure: false,
      customSlot: false,
      mot: false,
      whileYouWait: false,
      customer: state.customer,
      vehicle: state.vehicle,
      repairOperations: state.repairOperations,
      colDelDetails: [],
      sendEmailToCustomer: false,
    };

    rtc
      .createBooking(state.dealer.dealerId, booking)
      .then(() => {
        dispatch({
          type: Actions.Confirm,
          payload: {},
        });
      })
      .catch((err) => {
        const { message } = err;
        setError(
          message || "There was an error while trying to create booking: " + err
        );
      });
  };

  return (
    <>
      <h2 className="text-4xl font-bold text-center">Your booking</h2>
      <p className="font-bold text-center">
        Please check your booking information is correct
      </p>

      <div className="grid grid-cols-2 gap-x-16 gap-y-4 mt-4">
        <div>
          <FaCar size="2em" />
          <h3 className="text-2xl font-bold pb-4">Your Vehicle</h3>
        </div>
        <div>
          <p className=" text-xl text-slate-500">{state.vehicle.regnum}</p>
          <p className="text-xl text-slate-500">{state.vehicle.description}</p>
        </div>

        <div>
          <FaMapMarkerAlt size="2em" />
          <h3 className="text-2xl font-bold pb-4">Selected Location</h3>
        </div>
        <div>
          <p className="text-xl text-slate-500">{state.dealer.description}</p>
        </div>

        <div>
          <FaTools size="2em" />
          <h3 className="text-2xl font-bold pb-4">Your Service</h3>
        </div>
        <div>
          {state.repairOperations.map((ro) => (
            <p className="text-xl text-slate-500">{ro.description}</p>
          ))}
        </div>

        <div>
          <FaCalendarAlt size="2em" />
          <h3 className="text-2xl font-bold pb-4">Your Appointment</h3>
        </div>
        <div>
          <p className="text-xl text-slate-500">
            {APPOINTMENT_TYPE[state.availability.value]}
          </p>
          <p className="text-xl text-slate-500">
            {MONTHS[state.availability.date.getMonth()]}{" "}
            {state.availability.date.getDate()},{" "}
            {state.availability.date.getFullYear()} at {state.availability.time}
          </p>
        </div>

        <div>
          <FaRegUserCircle size="2em" />
          <h3 className="text-2xl font-bold pb-4">Your Details</h3>
        </div>
        <div>
          <p className="text-xl text-slate-500">
            {state.customer.forename} {state.customer.surname}
          </p>
          <p className="text-xl text-slate-500">{state.customer.email}</p>
          <p className="text-xl text-slate-500">{state.customer.phone1}</p>
          <p className="text-xl text-slate-500">{state.customer.phone2}</p>
          <p className="text-xl text-slate-500">
            {state.customer.address1} {state.customer.address2}{" "}
            {state.customer.address3} {state.customer.address4}
          </p>
        </div>
      </div>

      <h2 className="text-2xl font-bold text-center mt-8">Service Plan</h2>
      <p className="font-bold text-center">
        Do you have an active service plan with Feasa?
      </p>

      <div className="flex justify-center	gap-8">
        <div className="flex items-center gap-2">
          <Radio
            id="active-service-feasa-no"
            name="active-service-feasa"
            value="no"
            defaultChecked
          />
          <label htmlFor="active-service-feasa-no">No</label>
        </div>
        <div className="flex items-center gap-2">
          <Radio
            id="active-service-feasa-yes"
            name="active-service-feasa"
            value="yes"
          />
          <label htmlFor="active-service-feasa-yes">Yes</label>
        </div>
      </div>

      <p className="font-bold text-center pt-4">
        Would you like one of our team to provide you a service plan quote to
        help spread the cost of your vehicle maintenance into affordable monthly
        payments?
      </p>

      <div className="flex justify-center	gap-8">
        <div className="flex items-center gap-2">
          <Radio
            id="monthly-payments-no"
            name="monthly-payments"
            value="no"
            defaultChecked
          />
          <label htmlFor="monthly-payments-no">No</label>
        </div>
        <div className="flex items-center gap-2">
          <Radio
            id="monthly-payments-yes"
            name="monthly-payments"
            value="yes"
          />
          <label htmlFor="monthly-payments-yes">Yes</label>
        </div>
      </div>

      {error && (
        <Alert color="failure">
          There was an error during creation of the booking. Please try again
          later
        </Alert>
      )}

      <div className="mt-8">
        <Button
          type="button"
          color="dark"
          className="w-full md:w-3/4 md:m-auto"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

const Description = ({ state }) => <></>;

const tuple = { Page, Description };

export default tuple;
