const Page = ({ state, dispatch }) => {
  return (
    <>
      <h2 className="text-4xl font-bold text-center">
        Your booking is confirmed
      </h2>
    </>
  );
};

const Description = ({ state }) => <></>;

const tuple = { Page, Description };

export default tuple;
